import React from 'react';
import Lottie from 'react-lottie';

import { createAnchor } from 'components/Navigation';

import animationData from './benefits-bg.json';

import './Benefits.scss';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export const Benefits = ({ heading, anchor, text, detail }) => {
    const name = anchor.slug;
    const ref = createAnchor(name);

    return (
        <section className="benefits-section" id={name} ref={ref}>
            <div className="container">
                <h1>{heading}</h1>
                <h2>{text}</h2>
                <Lottie options={defaultOptions} />
                <ul>
                    {detail &&
                        detail.map(
                            ({ subheading, text: detailText }, index) => (
                                <li key={index}>
                                    <h3>{subheading}</h3>
                                    <p>{detailText}</p>
                                </li>
                            ),
                        )}
                </ul>
            </div>
        </section>
    );
};

export default Benefits;
