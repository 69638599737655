import React from 'react';

import { useNavigation } from 'components/Navigation';
import { useScrollContext } from 'components/ScrollProvider';

import './CenteredText.scss';

export const CenteredText = ({ heading, intro, call }) => {
    const { anchors } = useNavigation();
    const { scrollTo } = useScrollContext();

    const anchor = anchors['platform-content'];

    function click(e) {
        e.preventDefault();

        if (anchor && anchor.anchorTop) {
            scrollTo(anchor.anchorTop);
        }
    }

    return (
        <section className="wearable-compliance-section" id="platform">
            <div className="container">
                <h1>{heading}</h1>
                <h2>{intro}</h2>
                {call && <a href="#platform-content" onClick={click}>
                    <span className="label">{call}</span>
                    <span className="scroll-down"></span>
                </a>}
            </div>
        </section>
    );
};

export default CenteredText;
