import React, { useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { createAnchor } from 'components/Navigation';

import './VideoSection.scss';

export const VideoModal = ({ show, close, iframe }) => (
    <DialogOverlay
        isOpen={show}
        onDismiss={close}
        css={{
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.9)',
            // zIndex: 40,
            zIndex: 2147483001,
        }}
    >
        <DialogContent aria-label="Video">
            <button
                className="close-button"
                onClick={close}
                css={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    border: '0 none',
                    backgroundColor: 'transparent',
                    padding: 20,
                    margin: 0,
                    color: '#FFF',
                    cursor: 'pointer',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="28"
                    height="28"
                >
                    <path
                        css={{ fill: 'currentColor', strokeWidth: 0 }}
                        d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"
                    ></path>
                </svg>
            </button>
            <div
                css={{
                    position: 'absolute',
                    left: 48,
                    top: 48,
                    right: 48,
                    bottom: 48,
                }}
            >
                <iframe
                    src={iframe}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    css={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '100%',
                    }}
                ></iframe>
            </div>
        </DialogContent>
    </DialogOverlay>
);

export const VideoSection = ({ entries = [] }) => {
    if (!entries || entries.length < 1) return null;

    const ref = createAnchor('case-studies');

    const [openModal, setOpenModal] = useState();

    function close() {
        setOpenModal(undefined);
    }

    return (
        <section className="video-section" id="case-studies" ref={ref}>
            {entries.map(({ image, logo, header, text, video, call }, index) => (
                <div
                    key={index}
                    className="column"
                    style={{
                        backgroundImage: `url(${get(image, 'file.url')})`,
                    }}
                >
                    {video && (
                        <VideoModal
                            iframe={video}
                            close={close}
                            show={index === openModal}
                        />
                    )}
                    <div className="overlay no-hover">
                        <img src={get(logo, 'file.url')} />
                    </div>
                    <div className="overlay hover">
                        <img src={get(logo, 'file.url')} />
                        <h3>{header}</h3>
                        <p>{text}</p>
                        <span className="button-wrapper">
                        <Link className="cta" to={video}>
                            {call}
                        </Link>
                        </span>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default VideoSection;
