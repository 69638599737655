import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import ScrollProvider from 'components/ScrollProvider';
import { NavigationProvider } from 'components/Navigation';

import StickyNavigation from 'components/StickyNavigation';
import LeadSection from 'components/LeadSection';
import WearableCompliance from 'components/WearableCompliance';
import CenteredText from 'components/CenteredText';
import PlatformOverview from 'components/PlatformOverview';
import Benefits from 'components/Benefits';
import PrismPro from 'components/PrismPro';
import PilotPlan from 'components/PilotPlan';
import VideoSection from 'components/VideoSection';
import SiteFooter from 'components/SiteFooter';


import './index.scss';

class RootIndex extends React.Component {
    render() {
        const { site } = get(this, 'props.pageContext');
        const {
            title,
            introText,
            introCallToAction,
            introBackground,
            introVideo,
            featureTextHeader,
            featureTextBody,
            platformHeading,
            platformAnchor,
            platformText,
            platformCallToAction,
            platformNarrative,
            benefitsHeading,
            benefitsAnchor,
            benefitsText,
            benefitsDetail,
            prismProCallouts,
            prismProCallToAction,
            caseStudies,
            caseStudiesAnchor,
            pilotPlan,
        } = get(this, 'props.data.home');

        return (
            <ScrollProvider>
                <NavigationProvider>
                    <Helmet>
                      <title>{title || site.title || 'Mira'}</title>
                    </Helmet>
                    <StickyNavigation
                        sticky={true}
                        pages={site.navigationItems}
                        sections={[
                            platformAnchor,
                            benefitsAnchor,
                            caseStudiesAnchor,
                        ]}
                        loginLink={site.loginLink}
                        callToAction={site.callToAction}
                    />
                    <LeadSection
                        text={introText}
                        call={introCallToAction}
                        backgroundSources={introBackground}
                        modal={introVideo}
                    />
                    <CenteredText heading={featureTextHeader} intro={featureTextBody} />
                    <VideoSection entries={caseStudies} />
                  {/*<PlatformOverview
                        heading={platformHeading}
                        anchor={platformAnchor}
                        intro={platformText}
                        call={platformCallToAction}
                        narrative={platformNarrative}
                    />*/}
                    <Benefits
                        heading={benefitsHeading}
                        anchor={benefitsAnchor}
                        text={benefitsText}
                        detail={benefitsDetail}
                    />
                    <PrismPro
                        callouts={prismProCallouts}
                        call={prismProCallToAction}
                    />
                    <PilotPlan {...pilotPlan} />
                    <SiteFooter
                        links={site.footerNavigationItems}
                        social={site}
                        copyright={site.copyright}
                    />
                </NavigationProvider>
            </ScrollProvider>
        );
    }
}

export default RootIndex;

export const pageQuery = graphql`
    query HomePageQuery($id: String!) {
        site: allContentfulWebsite {
            edges {
                node {
                    id
                    title
                    navigationItems {
                        label
                        href
                    }
                    callToAction {
                        label
                        href
                    }
                    loginLink {
                        label
                        href
                    }
                    footerNavigationItems {
                        label
                        href
                    }
                    facebook
                    instagram
                    twitter
                    linkedin
                    copyright
                }
            }
        }
        home: contentfulHomePage(contentful_id: { eq: $id }) {
            id: contentful_id
            title
            introText
            introCallToAction
            introBackground {
                file {
                    url
                    contentType
                }
            }
            introVideo
            featureTextHeader
            featureTextBody
            platformHeading
            platformAnchor {
                label
                slug
            }
            platformText
            platformCallToAction
            platformNarrative {
                id
                heading
                text
                backgroundImage {
                    file {
                        url
                    }
                }
                backgroundImageBlurred {
                    file {
                        url
                    }
                }
                overlayImage {
                    file {
                        url
                    }
                }
                call: callToAction {
                    label
                    href
                }
            }
            benefitsHeading
            benefitsAnchor {
                label
                slug
            }
            benefitsText
            benefitsDetail {
                subheading
                text
            }
            prismProCallouts
            prismProCallToAction {
                label
                href
            }
            caseStudies {
                image {
                    file {
                        url
                    }
                }
                logo {
                    file {
                        url
                    }
                }
                header
                text
                video: videoUrl
                call: callToAction
            }
            caseStudiesAnchor {
                label
                slug
            }
            pilotPlan {
                heading
                text
                call: callToAction {
                    label
                    href
                }
            }
        }
    }
`;
