import React, { createRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';

import { createAnchor } from 'components/Navigation';

import BoundingBox, { useBoundingContext } from 'components/BoundingBox';

import './PrismPro.scss';

export const TriggerOnVisible = ({ children }) => {
    const ref = createRef();
    const [triggered, setTriggered] = useState(false);

    const { visible } = useBoundingContext();

    useEffect(() => {
        const { current } = ref;

        if (current && visible && !triggered) {
            setTimeout(() => current.play(), 1000);

            setTriggered(true);
        }
    }, [visible]);

    return children({ ref, visible: triggered || visible });
};

export const ProductVideo = ({ src }) => (
    <BoundingBox>
        <TriggerOnVisible>
            {({ ref: innerRef, visible }) => (
                <motion.div
                    initial="hidden"
                    animate={visible ? 'visible' : 'hidden'}
                    variants={{
                        visible: {
                            opacity: 1,
                            transition: { delay: 1 },
                        },
                        hidden: { opacity: 0 },
                    }}
                >
                    {/* NOTE: muted and playsInline MUST be passed as strings, or they are ignored by the browser. */}
                    <video
                        ref={innerRef}
                        muted="true"
                        playsInline="true"
                        allow="autoplay"
                    >
                        <source src={src} type="video/mp4" />
                    </video>
                </motion.div>
            )}
        </TriggerOnVisible>
    </BoundingBox>
);

export const PrismPro = ({ callouts, call }) => {
    const ref = createAnchor('prism-pro');

    return (
        <section className="prism-pro-section" id="prism-pro" ref={ref}>
            <div className="container">
                <h1>
                    Powered by the <span>Prism&nbsp;Pro</span>
                </h1>
                <div className="touchpoints">
                    <div className="image-wrapper">
                        <ProductVideo src="assets/powered-by/prism-pro-1.mp4" />
                    </div>
                    {callouts &&
                        callouts
                            .slice(0, 2)
                            .map((text, index) => (
                                <TouchPoint key={index}>{text}</TouchPoint>
                            ))}
                </div>
                <div className="touchpoints">
                    <div className="image-wrapper">
                        <ProductVideo src="assets/powered-by/prism-pro-2.mp4" />
                    </div>
                    {callouts &&
                        callouts
                            .slice(2, 4)
                            .map((text, index) => (
                                <TouchPoint key={index}>{text}</TouchPoint>
                            ))}
                </div>
                {call && (
                    <Link className="cta" to={call.href}>
                        {call.label}
                    </Link>
                )}
            </div>
        </section>
    );
};

export default PrismPro;

export const TouchPoint = ({ children, left, orient = 'left' }) => (
    <h2 className="powered-by-point">{children}</h2>
);
