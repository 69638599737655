import React, { useState, useRef } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';

import composeClasses from 'lib/composeClasses';

import './LeadSection.scss';

export const LeadSection = ({ text, call, backgroundSources, modal }) => {
    const [playing, setPlaying] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const videoRef = useRef(null);

    function open() {
        const { current } = videoRef;

        setPlaying(false);
        setShowDialog(true);

        if (current) {
            current.pause();
        }
    }

    function close() {
        const { current } = videoRef;

        setPlaying(true);
        setShowDialog(false);

        if (current) {
            current.play();
        }
    }

    return (
        <section
            className={composeClasses(
                'lead-section',
                playing && 'video-playing',
            )}
        >
            <div className="video-overlay"></div>
            {backgroundSources && (
                // NOTE: muted and playsInline MUST be passed as strings, or they are ignored by the browser.
                <video
                    muted="true"
                    playsInline="true"
                    allow="autoplay"
                    autoPlay="autoplay"
                    loop="loop"
                    ref={videoRef}
                >
                    {backgroundSources.map(({ file }, index) => (
                        <source
                            key={index}
                            src={file.url}
                            type={file.contentType}
                        />
                    ))}
                </video>
            )}
            <div className="container">
                <p>{text}</p>
                <button onClick={open}>
                    <span className="arrow"></span>
                    <span className="label">{call}</span>
                </button>
            </div>
            <DialogOverlay
                isOpen={showDialog}
                onDismiss={close}
                css={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    // zIndex: 40,
                    zIndex: 2147483001,
                }}
            >
                <DialogContent aria-label="Video">
                    <button
                        className="close-button"
                        onClick={close}
                        css={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            border: '0 none',
                            backgroundColor: 'transparent',
                            padding: 20,
                            margin: 0,
                            color: '#FFF',
                            cursor: 'pointer',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="28"
                            height="28"
                        >
                            <path
                                css={{ fill: 'currentColor', strokeWidth: 0 }}
                                d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"
                            ></path>
                        </svg>
                    </button>
                    <div
                        css={{
                            position: 'absolute',
                            left: 48,
                            top: 48,
                            right: 48,
                            bottom: 48,
                        }}
                    >
                        <iframe
                            src={modal}
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            css={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                            }}
                        ></iframe>
                    </div>
                </DialogContent>
            </DialogOverlay>
        </section>
    );
};

export default LeadSection;
